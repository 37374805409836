<template>
  <div class="pa-2">

    <page-card
        cardTitle="Page 2"
        cardTitleIcon="mdi-playlist-edit"
        cardSubtitle="Inside Front Cover"
    >
        <v-card-text>
            <p>All you need on this page are a short welcome to your guests and your favourite photo of the two of you as a couple.</p>
            <p>The welcome should be two or three sentences at most. Please be aware of the character limit for this section, shown below the box. When previewing this page please ensure all your text is included.</p>
            <p>If you are stuck for inspiration, here are a couple of suggestions for you.  Feel free to copy and paste the contents into the text box. There is no need to include your names – the system will put them in automatically!</p>
            <p class='font-weight-medium'>Example 1</p>
            <p class='font-italic px-2'>We are delighted you are able to share our special day with us! We have put together this booklet for you to have some fun, and to test your knowledge of us as a couple. We hope you enjoy the activities and have a great day. Lots of love</p>
            <p class='font-weight-medium'>Example 2</p>
            <p  class='font-italic px-2'>Thank you for coming to our wedding. We are so happy you are able to share this day with us. We hope you enjoy our Love Story experience and take part in the activities we have created for you. Have a lovely day. With love</p>
      </v-card-text>


      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="submit"
          class="pa-4"
          :disabled="readOnlyMode"
      >
  
        <v-textarea
            v-model="wedIntro"
            :rules="introRules"
            label="Introductory Text"
            :counter="270"
            outlined
            @change="fieldValueChanged"
        />

            <v-card-text>
              
              <p>
                The image below is the shape of the photo required.
              </p>
              <img
                class="mb-4"
                style="max-width: 350px; width: 100%;"
                src="@/assets/img/photo_sizing_w92mm_h118mm.jpg"
                alt="Image shape"
              />   

            </v-card-text>

        <image-upload
            ref="imageUpload"
            sourceType="page"
            :sourceId=pageName
            fileInputLabel="Inside front cover photo"
        />

        <div class="mt-8">
          <v-btn
              :disabled="!valid || readOnlyMode"
              type="submit"
              color="green"
              class="mr-4"
          >
            Save
          </v-btn>
          <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
          <v-btn :disabled="!valid" @click="preview">Preview</v-btn>
        </div>

      </v-form>

    </page-card>

  </div>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";
import PageCard from "@/components/PageCard";
import {form} from "@/mixins/form";

export default {
  name: "Page2",
  components: {
    ImageUpload,
    PageCard
  },
  mixins: [form],
  data: () => ({
    pageName: "p2",
    valid: true,
  }),
  computed: {
    wedIntro: {
      get: function () {
        // TODO: Create a getter that finds the object by searching for the "p2" key instead.
        return this.$store.state.order.pages[1].data.wed_intro
      },
      set(value) {
        this.$store.commit("setP2WedIntro", value)
      }
    },
  }
}
</script>
